import React from "react";
import Layout from "../components/Layout/Layout";

import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";

import ForHomeHero from "../components/Home/ForHomeHero";
import Features from "../components/Home/Features";
import HalfImage from "../components/Home/HalfImage";
import HomeCarousel from "../components/Home/HomeCarousel";
import ProblemCounter from "../components/Home/ProblemCounter";

import "../styles/pages/home.scss";

export default function SignIn(props) {
  let redirectUrl;
  if (props && props.location && props.location.search) {
    let searchParams = new URLSearchParams(props.location.search);
    redirectUrl = searchParams.get("redirectUrl");
  }
  return (
    <Layout signIn={true} redirectUrl={redirectUrl}>
      <ForHomeHero />
      <Features />
      <HalfImage />
      <HomeCarousel />
      <ProblemCounter />
    </Layout>
  );
}
