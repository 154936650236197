import React, { useEffect } from "react";
import { Button } from "react-md";
import { getDeltaMathAPI } from "../../utilities/utilities";

export default function Plans(props) {
  const [toggleState, setToggleState] = React.useState("school_district");
  const [planData, setPlanData] = React.useState({
    Plus: null,
    Integral: null,
    type: null,
    key: null,
  })

  useEffect(() => {
    getIndividualPlanPrices();
  }, []);

  const handleIndividualClick = (e) => {
    setToggleState("individual");
  };
  const handleSiteClick = (e) => {
    setToggleState("school_or_district");
  };
  const getIndividualPlanPrices = async () => {
    try {
      const res = await fetch(getDeltaMathAPI() + "/public/quote/price-scheme/default");
      const resJSON = await res.json();
      setPlanData({
        ...resJSON.individual,
        type: resJSON.type,
        key: resJSON.key,
      });
    } catch (err) {
      console.error(err);
      setPlanData({
        Plus: "---",
        Integral: "---",
      });
    }
  };
  const getSchoolYearString = (type, key) => {
    if (!key || !type) {
      return "---";
    }

    const left2 = key.slice(0, 2);
    const right2 = key.slice(3, 5);
    
    if (type === "full_year") {
      return `'${left2}-'${right2} School Year`;
    } else if (type === 'pro_rated') {
      return `'${left2}-'${right2} School Year (Prorated)`;
    }
  }
  return (
    <>
      <div className="individual-site-toggle-container">
        <Button
          className={
            "account-type-toggle-button " +
            (toggleState !== "individual" ? "selected" : "")
          }
          theme="primary"
          themeType="outline"
          onClick={handleSiteClick}
        >
          School/District
        </Button>
        <Button
          className={
            "account-type-toggle-button " +
            (toggleState === "individual" ? "selected" : "")
          }
          theme="primary"
          themeType="outline"
          onClick={handleIndividualClick}
        >
          Individual
        </Button>
      </div>
      <div className="teachers__plansContainer-planCardContainer individual_cards_container">
        {toggleState === "individual" && (
          <>
            {/* CARD 1 */}
            <div className="teachers__plansContainer-planCard">
              <div className="teachers__plansContainer-planCard-headerContainer teachers__plansContainer-planCard-headerContainer1">
                <h2 className="teachers__plansContainer-planCard-header teachers__plansContainer-planCard-header1">
                  DeltaMath Teacher
                </h2>
              </div>

              <div className="teachers__plansContainer-planCard-info individual_license">
                <div className="teachers__plansContainer-planCard-info-titleContainer">
                  <h2
                    className="teachers__plansContainer-planCard-info-title"
                    style={{ marginBottom: "20px" }}
                  >
                    FREE
                  </h2>
                </div>
                <ul className="teachers__plansContainer-planCard-info-list">
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    Unlimited assignments
                  </li>
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    Unlimited student practice
                  </li>
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    1800+ premade problem types
                  </li>
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    Autograding
                  </li>
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    Detailed student data, including <br />
                    time-stamps
                  </li>
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    Evidence of student progress/learning
                  </li>
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    Cheat prevention tools
                  </li>
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    Copy and share assignments
                  </li>
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    Google Single Sign-On (SSO)
                  </li>
                </ul>
              </div>
            </div>

            {/* CARD 2 */}
            <div className="teachers__plansContainer-planCard teachers__plansContainer-planCard-plus">
              <div className="teachers__plansContainer-planCard-headerContainer teachers__plansContainer-planCard-headerContainer2">
                <h2 className="teachers__plansContainer-planCard-header teachers__plansContainer-planCard-header2">
                  DeltaMath PLUS
                </h2>
                <h4 className="teachers__plansContainer-planCard-subheader">
                  Individual Teacher
                </h4>
              </div>

              <div className="teachers__plansContainer-planCard-info individual_license">
                <div className="teachers__plansContainer-planCard-info-titleContainer">
                  <h2 className="teachers__plansContainer-planCard-info-title">
                    {planData.Plus ? `$${planData.Plus} / teacher` : <span className="loading-spinner"></span>}
                  </h2>
                  <i>{getSchoolYearString(planData.type, planData.key)}</i>
                </div>
                <ul className="teachers__plansContainer-planCard-info-list">
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    All the features of DeltaMath Teacher
                  </li>
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    <strong>PLUS:</strong>
                  </li>
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    <a href="#videos">
                      Instructional videos for every <br /> problem type
                    </a>
                  </li>
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    <a href="#videos">Assign videos from YouTube</a>
                  </li>
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    <a href="#automated_test_corrections">Create a test</a>
                  </li>
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    <a href="#automated_test_corrections">Automated Test Corrections</a>
                  </li>
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    <a href="#problem_types">Selection of problem sub-types</a>
                  </li>
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    <a href="#advanced_assignment_options">Assign specific problems</a>
                  </li>
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    <a href="#advanced_assignment_options">Assign to individuals or groups</a>
                  </li>
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    <a href="#create_your_own_problem">Create your own problem</a>
                  </li>
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    <a href="#co_teachers">Add co-teachers</a>
                  </li>
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    <a href="#folder_file_system">Folder/file system</a>
                  </li>
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    <a href="#integrations">
                      Google Classroom Integration
                    </a>
                  </li>
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    <a href="#standard_maps">Map to standards</a> <br />
                    (US + Canada)
                  </li>
                </ul>
              </div>
              <div className="get-quote-button-wrapper">
                <Button
                  className="get-quote-button"
                  id="blue-btn-2"
                  theme="secondary"
                  themeType="contained"
                  onClick={props.enableQuote}
                >
                  Get a Quote
                </Button>
              </div>
            </div>


            {/* CARD 3 */}
            <div className="teachers__plansContainer-planCard teachers__plansContainer-planCard-integral">
              <div className="teachers__plansContainer-planCard-headerContainer teachers__plansContainer-planCard-headerContainer3">
                <h2 className="teachers__plansContainer-planCard-header teachers__plansContainer-planCard-header3">
                  DeltaMath INTEGRAL
                </h2>
                <h4 className="teachers__plansContainer-planCard-subheader">
                  Individual Teacher
                </h4>
              </div>

              <div className="teachers__plansContainer-planCard-info individual_license">
                <div className="teachers__plansContainer-planCard-info-titleContainer">
                  <h2 className="teachers__plansContainer-planCard-info-title">
                    {planData.Integral ? `$${planData.Integral} / teacher` : <span className="loading-spinner"></span>}                    
                  </h2>
                  <i>{getSchoolYearString(planData.type, planData.key)}</i>
                </div>
                <ul className="teachers__plansContainer-planCard-info-list">
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    All the features of DeltaMath Plus
                  </li>
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    <strong>INTEGRATING:</strong>
                  </li>
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    <a href="#print">Print assignments/assessments to PDF</a>
                  </li>
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    <a href="#upload_student_work">Upload student work/notes</a>
                  </li>
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    <a href="#integration">Canvas/Schoology Integration</a>
                  </li>
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    <i>Each individual teacher will be given their own LTI key and secret</i>
                  </li>
                </ul>
              </div>
              <div className="get-quote-button-wrapper">
                  <Button
                    className="get-quote-button"
                    theme="secondary"
                    themeType="contained"
                    onClick={props.enableQuote}
                  >
                    Get a Quote
                  </Button>
                </div>
            </div>
          </>
        )}
        {toggleState !== "individual" && (
          <>
            {/* CARD 1 */}
            <div className="teachers__plansContainer-planCard">
              <div className="teachers__plansContainer-planCard-headerContainer teachers__plansContainer-planCard-headerContainer1">
                <h2 className="teachers__plansContainer-planCard-header teachers__plansContainer-planCard-header1">
                  DeltaMath Teacher
                </h2>
              </div>

              <div className="teachers__plansContainer-planCard-info school_district_license">
                <div className="teachers__plansContainer-planCard-info-titleContainer">
                  <h2 className="teachers__plansContainer-planCard-info-title">
                    FREE
                  </h2>
                </div>
                <ul className="teachers__plansContainer-planCard-info-list">
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    Unlimited assignments
                  </li>
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    Unlimited student practice
                  </li>
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    1800+ premade problem types
                  </li>
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    Autograding
                  </li>
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    Detailed student data, including <br />
                    time-stamps
                  </li>
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    Evidence of student progress/learning
                  </li>
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    Cheat prevention tools
                  </li>
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    Copy and share assignments
                  </li>
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    Google Single Sign-On (SSO)
                  </li>
                </ul>
              </div>
            </div>
            {/* CARD 2 */}
            <div className="teachers__plansContainer-planCard teachers__plansContainer-planCard-plus">
              <div className="teachers__plansContainer-planCard-headerContainer teachers__plansContainer-planCard-headerContainer2">
                <h2 className="teachers__plansContainer-planCard-header teachers__plansContainer-planCard-header2">
                  DeltaMath PLUS
                </h2>
                <h4 className="teachers__plansContainer-planCard-subheader">
                  School/District License
                </h4>
              </div>

              <div className="teachers__plansContainer-planCard-info school_district_license">
                <div className="teachers__plansContainer-planCard-info-titleContainer">
                  <h2 className="teachers__plansContainer-planCard-info-title school-district">
                    Site dependent price
                  </h2>
                  <i>{getSchoolYearString('full_year', planData.key)}</i>
                </div>
                <ul className="teachers__plansContainer-planCard-info-list">
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    Covers all site-based teachers
                  </li>
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    All the features of DeltaMath Teacher
                  </li>
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    <strong>PLUS:</strong>
                  </li>
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    <a href="#videos">
                      Instructional videos for every <br /> problem type
                    </a>
                  </li>
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    <a href="#videos">Assign videos from YouTube</a>
                  </li>
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    <a href="#automated_test_corrections">Create a test</a>
                  </li>
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    <a href="#automated_test_corrections">Automated Test Corrections</a>
                  </li>
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    <a href="#problem_types">Selection of problem sub-types</a>
                  </li>
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    <a href="#advanced_assignment_options">Assign specific problems</a>
                  </li>
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    <a href="#advanced_assignment_options">Assign to individuals or groups</a>
                  </li>
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    <a href="#create_your_own_problem">Create your own problem</a>
                  </li>
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    <a href="#co_teachers">Add co-teachers</a>
                  </li>
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    <a href="#folder_file_system">Folder/file system</a>
                  </li>
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    <a href="#integrations">Google Classroom Integration</a>
                  </li>
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    <a href="#standard_maps">Map to standards</a> <br />
                    (US + Canada)
                  </li>
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    <a href="#plus_admin_portal">Admin portal for onboarding and
                      <br />
                      teacher data views</a>
                    <br />
                  </li>
                </ul>
              </div>
              <div className="get-quote-button-wrapper">
                  <Button
                    className="get-quote-button"
                    id="blue-btn-1"
                    theme="secondary"
                    themeType="contained"
                    onClick={props.enableQuote}
                  >
                    Get a Quote
                  </Button>
                </div>
            </div>

            {/* CARD 3 */}
            <div className="teachers__plansContainer-planCard teachers__plansContainer-planCard-integral">
              <div className="teachers__plansContainer-planCard-headerContainer teachers__plansContainer-planCard-headerContainer3">
                <h2 className="teachers__plansContainer-planCard-header teachers__plansContainer-planCard-header3">
                  DeltaMath INTEGRAL
                </h2>
                <h4 className="teachers__plansContainer-planCard-subheader">
                  School/District License
                </h4>
              </div>

              <div className="teachers__plansContainer-planCard-info school_district_license">
                <div className="teachers__plansContainer-planCard-info-titleContainer">
                  <h2 className="teachers__plansContainer-planCard-info-title school-district">
                    Site dependent price
                  </h2>
                  <i>{getSchoolYearString('full_year', planData.key)}</i>
                </div>
                <ul className="teachers__plansContainer-planCard-info-list">
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    Covers all site-based teachers
                  </li>
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    All the features of DeltaMath Plus
                  </li>
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    <strong>INTEGRATING:</strong>
                  </li>
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    <a href="#integrations">
                      Canvas or Schoology integration
                    </a>
                  </li>
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    <a href="#integrations">
                      Clever or ClassLink integration
                    </a>
                  </li>
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    <a href="#print">Print assignments/assessments to PDF</a>
                  </li>
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    <a href="#upload_student_work">Upload student work/notes</a>
                  </li>
                  <li className="teachers__plansContainer-planCard-info-list-item">
                    <a href="#integral_admin_portal">Additional admin features, including:</a>
                    <ul className="sublist">
                      <li className="teachers__plansContainer-planCard-info-list-item">
                        <a href="#pushed_assignments">Push assessments to teachers <br />
                          and sections</a> (i.e. diagnostics)
                      </li>
                      <li className="teachers__plansContainer-planCard-info-list-item">
                        <a href="#student_performance_reports">Student performance by standard</a>
                        <br />
                        (US + Canada)
                      </li>
                      <li className="teachers__plansContainer-planCard-info-list-item">
                        <a href="#integral_admin_portal">Bulk data export</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div className="get-quote-button-wrapper">
                  <Button
                    className="get-quote-button"
                    theme="secondary"
                    themeType="contained"
                    onClick={props.enableQuote}
                  >
                    Get a Quote
                  </Button>
                </div>
            </div>
          </>
        )}
      </div>
      <p className="footnote">
        <a
          href="https://files.deltamath.com/DeltaMath-Flyer.pdf"
          target="_blank"
          rel="noreferrer"
        >
          Click here</a> to learn which plan is right for you.
      </p>
    </>
  );
}
